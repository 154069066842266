import uuid from "uuid";
import Vue from "vue";
import Vuex from "vuex";
import permissions from "../common/permissions";
import wwwlDefaults from "../common/wwwlDefaults.json";

Vue.use(Vuex);

const methods = {
  updateNextPreviousUids(state, windowUids) {
    if (state.inViewerWindow) {
      return;
    }

    // Update next and previous study UIDs for all open windows. This should handle if a studyUid goes MIA
    // when worklist updates and selected or next/previous study no longer matches filter.
    //
    const userId = state.keycloak.tokenParsed.sub;
    const filtered = state.worklistSorted.filter((item) => {
      let include = item._cvi && !item.oo;
      if (
        include &&
        permissions.hasPermission(item.group, permissions.CAN_LOCK_STUDY)
      ) {
        include = item.lock == "" || item.lock == userId;
      }
      return include;
    });
    for (var i = 0; i < windowUids.length; i++) {
      const windowUid = windowUids[i];
      const studyUid = state._selectedStudyUids[windowUid];
      var j = 0;
      var k = 0;
      for (j = 1; j < filtered.length; j++) {
        if (filtered[j].study_uid == studyUid) {
          // Make sure to pick a study that is still in current worklist as sortedWorklist may be stale.
          let nextStudyUid = null;
          for (k = j - 1; k >= 0; k--) {
            const tmpNextStudyUid = filtered[k].study_uid;
            if (
              state.worklist.filter((item) => {
                return item.study_uid == tmpNextStudyUid;
              }).length > 0
            ) {
              nextStudyUid = tmpNextStudyUid;
              break;
            }
          }
          state._nextStudyUids[windowUid] = nextStudyUid;
          break;
        }
      }
      for (j = 0; j < filtered.length - 1; j++) {
        if (filtered[j].study_uid == studyUid) {
          // Make sure to pick a study that is still in current worklist as sortedWorklist may be stale.
          let previousStudyUid = null;
          for (k = j + 1; k < filtered.length; k++) {
            const tmpPreviousStudyUid = filtered[k].study_uid;
            if (
              state.worklist.filter((item) => {
                return item.study_uid == tmpPreviousStudyUid;
              }).length > 0
            ) {
              previousStudyUid = tmpPreviousStudyUid;
              break;
            }
          }
          state._previousStudyUids[windowUid] = previousStudyUid;
          break;
        }
      }

      if (windowUid == state.uid) {
        state._nextStudyUid = state._nextStudyUids[windowUid];
        state._previousStudyUid = state._previousStudyUids[windowUid];
      }
      Vue.$log.debug(
        `windowUid=[${windowUid}] previous=[${state._previousStudyUids[windowUid]}] next=[${state._nextStudyUids[windowUid]}]`
      );
    }
  },
};

export default new Vuex.Store({
    state: {
        uid: uuid.v4(),
        dicomWebQidoUrl: '',
        groupAetLut: {},
        keycloak: null,
        permissions: {
            customers: [],
            tenants: [],
        },
        userGlobalSettings: {
            default_customer_id: '',
        },
        userProfile: null,
        reportId: '',
        // actionComponent: component in side panel of 'this' window.
        activeComponent: '',
        // activeStudyUid: study for component in side panel of 'this' window.
        activeStudyUid: '',
        // activeStudyWorklistEntry: copy of worklist entry associated with activeStudyUid
        activeStudyWorklistEntry: null,
        // _selectedStudyUid: study opened in 'this' window.
        _selectedStudyUid: '',
        // _selectedStudyUids: studies opened in all viewer or report components/windows (used by worklist window).
        _selectedStudyUids: {},
        // _nextStudyUid: next study for review on worklist sort.
        _nextStudyUid: null,
        // _previousStudyUid: previous study for review based on worklist sort.
        _previousStudyUid: null,
        // _nextStudyUids: cache of previous studies for navigation (used by worklist window).
        _nextStudyUids: {},
        // _previousStudyUids: cache of previous studies for navigation (used by worklist window).
        _previousStudyUids: {},
        // list of studies selected on worklist.
        checkedStudies: [],
        // current customer (initialized to empty meaning not selected)
        customerId: '',
        // all customers/tenants for this user
        customers: {
        },
        patientHistoryDocId: '',
        docServicesUrl: '',
        uploadLimitMb: 1,
        queryLimit: 10000,
        refreshViewer: false,
        locale: (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language,
        mgmtReportItems: [],
        mgmtReportRefreshing: false,
        mgmtReportTS: 0,
        mgmtReportType: 'productivity',
        openStudies: {},
        prevRoute: 'worklist',
        reportFonts: [
            'Arial',
            'Calibri', 
            'Cambria',
            'Candara',
            'Courier New',
            'Georgia',
            'Impact',
            'Symbol',
            'Times New Roman',
            'Verdana',
            'Webdings'
        ],
        reportPhraseLut: {},
        reportSettings: {
            "capitalize_sentences": false
        },
        reportWindowBox: {
            x: window.screen.availWidth / 2,
            y: 0,
            w: window.screen.availWidth / 2,
            h: window.screen.availHeight
        },
        reportWindowsEditing: {},
        reportWindows: {},
        secondaryWorklist: [],
        studyStatusTs: new Date().toISOString(),
        launchedByRis: false,
        inViewerWindow: false,
        viewerStudyList: [],
        viewerSelectedStudyI: 0,
        viewerWindowBox: {
            x: window.screen.availWidth / 2,
            y: 0,
            w: window.screen.availWidth / 2,
            h: window.screen.availHeight
        },
        viewerWindowsRoute: {},
        viewerWindows: {},
        webServicesBaseUrl: '',
        worklist: [],
        worklistCount: 0,
        cachedWorklistName: '',
        adHocWorklistName: '__Ad_HoC__',
        worklistName: '',
        worklistNames: [],
        worklistPerPage: 10,
        worklistCurrentPage: 1,
        worklistRefreshFlag: true, // false if error during last refresh
        worklistRefreshInterval: 5, // in minutes
        worklistRefreshing: '',
        worklistRefreshTime: '---', // last time worklist was refreshed
        worklistSettings: {},
        worklistSorted: [],
        worklistSortBy: 'study_date_time',
        worklistSortDesc: true,
        worklistActions: ['viewer_full', 'assign_study', 'export_study', 'share_study', 'mark_stat', 'edit_patient'],
        worklistColumns: [ 'actions', 'patient_name', 'patient_age', 'dob', 'patient_id', 'modality', 'study_desc', 'study_date_time' ],
        worklistColumnWidths: {},
        worklistTableFilters: {},
        worklistAltViewers: [],
        worklistViewers: { 
            "dbl_click_route": "viewer",
            "lite_quality": 70,
            "lite_annotation_color": "#85aed3",
            "measurement_units": "mm",
            "reports_open_in": "sidePanel",
            "viewers_open_in": "worklistWindow",
            "cache_size": 2,
        },
        wwwlFavorites: wwwlDefaults.data,
        exportStudy: {
            "include_reports": false,
            "include_viewer": false,
        }
    },
    getters: {
        user: (state) => {
            let userProfile = state.userProfile
            let userName = ""
            if (userProfile != null) {
                if (("firstName" in userProfile) && ("lastName" in userProfile)) {
                    if ((userProfile["firstName"] !== undefined) && (userProfile["lastName"] !== undefined)) {
                        userName = userProfile["firstName"]+" "+userProfile["lastName"]
                    }
                }
            }
            return userName
        },
        worklistEntryForStudy: (state) => (studyUid) => {
            var entry = null
            for(var j=0; j < state.worklist.length; j++) {
                if (state.worklist[j].study_uid == studyUid) {
                    entry = state.worklist[j]
                    break
                }
            }
            if (entry == null) {
                // Check if there is a secondary study entry available...
                //
                for(let j=0; j < state.secondaryWorklist.length; j++) {
                    if (state.secondaryWorklist[j].study_uid == studyUid) {
                        entry = state.secondaryWorklist[j]
                        break
                    }
                }
            }
            if ((entry == null) && (state.activeStudyWorklistEntry != null) && (state.activeStudyWorklistEntry.study_uid == studyUid)) {
                entry = state.activeStudyWorklistEntry
            }
            return entry;
        },
        worklistEntryForSelectedStudyUid: (state) => (windowUid = '') =>  {
            var entry = null
            const selectedStudyUid = state._selectedStudyUids[windowUid]
            for(let j=0; j < state.worklist.length; j++) {
                if (state.worklist[j].study_uid == selectedStudyUid) {
                    entry = state.worklist[j]
                    break
                }
            }
            if (entry == null) {
                // Check if there is a secondary study entry available...
                //
                for(let j=0; j < state.secondaryWorklist.length; j++) {
                    if (state.secondaryWorklist[j].study_uid == selectedStudyUid) {
                        entry = state.secondaryWorklist[j]
                        break
                    }
                }
            }
            return entry;
        },
        worklistEntryFromMgmtReport: (state) => (studyUid) => {
            var entry = null
            for(var j=0; j < state.mgmtReportItems.length; j++)
            {
                if (state.mgmtReportItems[j]._study_iuid == studyUid) {
                    // Create minimal worklist type entry from management report entry
                    //
                    const rptEntry = state.mgmtReportItems[j]
                    entry = {
                        study_uid: rptEntry._study_iuid,
                        study_date: rptEntry._study_date,
                        group: rptEntry['Clinic / Tenant Name'],
                        patient_id: rptEntry['Patient ID'],
                        patient_name: rptEntry['Patient Name'],
                        stat: rptEntry._s
                    }
                    break
                }
            }
            return entry;
        },
        mgmtReportEntriesForStudy: (state) => (studyUid) => {
            var entries = []
            for(var j=0; j < state.mgmtReportItems.length; j++)
            {
                if (state.mgmtReportItems[j]._study_iuid == studyUid) {
                    entries.push(state.mgmtReportItems[j])
                }
            }
            return entries;
        },
        nextStudyUid: (state) => (windowUid) => {
            var study_uid = state._nextStudyUids[windowUid] || state._nextStudyUid
            return study_uid;
        },
        previousStudyUid: (state) => (windowUid) => {
            var study_uid = state._previousStudyUids[windowUid] || state._previousStudyUid
            return study_uid;
        },
        selectedStudyUid: (state) => (windowUid) => {
            let studyUid = state._selectedStudyUids[windowUid]
            if (studyUid === undefined) {
                studyUid = ''
            }
            return studyUid
        },
        worklistFilter: (state) => (worklistName) => {
            var entry = null
            const keys = Object.keys(state.worklistSettings)
            for(var j=0; j < keys.length; j++) {
                if (worklistName == keys[j]) {
                    entry = state.worklistSettings[worklistName];
                    break;
                }
            }
            return entry;
        },
        defaultWorklistName: (state) => {
            var worklistName = state.adHocWorklistName
            const keys = Object.keys(state.worklistSettings)
            for(var j=0; j < keys.length; j++) {
                if (state.worklistSettings[keys[j]].default) {
                    worklistName = keys[j];
                    break;
                }
            }
            Vue.$log.debug("default worklist filter=["+worklistName+"]")
            return worklistName;
        },
        aetForGroup: (state) => (group) => {
            var aet = ''
            try {
                if (Object.keys(state.groupAetLut).includes(group)) {
                    aet = state.groupAetLut[group]
                }
            }
            catch {
                aet = ''
            }
            return aet;
        },
        groups: (state) => {
            return state.keycloak.tokenParsed.groups;
        },
        isReportOpenForEdit: (state) => (windowUid) => {
            var editing = (state.activeComponent == 'ReportEditor')
            editing |= ((state.reportWindowsEditing[windowUid] !== undefined) && state.reportWindowsEditing[windowUid])
            return editing
        },
        isAnyReportOpenForEdit: (state) => {
            var editing = (state.activeComponent == 'ReportEditor')
            const uids = Object.keys(state.reportWindowsEditing)
            for (var k = 0; k < uids.length; k++) {
                let uid = uids[k]
                editing |= state.reportWindowsEditing[uid]
            }
            return editing
        },
        openReportsInWindow: (state) => {
            return (state.worklistViewers.reports_open_in == "window")
        },
        openViewersInWindow: (state) => {
            return (state.worklistViewers.viewers_open_in == "viewerWindow")
        }
    },
    mutations: {
        addSecondaryWorklistEntry(state, payload) {
            const filtered =  state.secondaryWorklist.filter(item => {
                return item.study_uid == payload.study_uid
            })
            if (filtered.length == 0) {
                state.secondaryWorklist.push(payload)
            }
        },
        removeSecondaryWorklistEntry(state, payload) {
            // payload is studyUid
            state.secondaryWorklist = state.secondaryWorklist.filter(item => {
                return item.study_uid != payload
            })
        },
        changeCachedWorklistName(state, payload) {
            state.cachedWorklistName = payload
        },
        changeCheckedStudies(state, payload) {
            state.checkedStudies = payload
        },
        changeCustomerId(state, payload) {
            state.customerId = payload
        },
        changeCustomers(state, payload) {
            const customerIds = Object.keys(payload)
            state.customers = payload
            if ((state.customerId == '') && (customerIds.length > 0)) {
                state.customerId = customerIds[0]
            }
            else if (!customerIds.includes(state.customerId)) {
                state.customerId = ''
            }
        },
        changeDicomWebQidoUrl(state, payload) {
            state.dicomWebQidoUrl = payload
        },
        changeGroupAetLut(state, payload) {
            state.groupAetLut = payload
        },
        changeKeycloak(state, payload) {
            state.keycloak = payload
        },
        changePermissions(state, payload) {
            state.permissions = payload
        },
        changeLocale(state, payload) {
            state.locale = payload
        },
        changePatientHistoryDocId(state, payload) {
            state.patientHistoryDocId = payload
        },
        changeReportId(state, payload) {
            state.reportId = payload
        },
        changeActiveComponent(state, payload) {
            state.activeComponent = payload
        },
        changeActiveStudyUid(state, payload) {
            state.activeStudyUid = payload
            if (state.activeStudyUid !== '') {
                state.activeStudyWorklistEntry = null
                for(var j=0; j < state.worklist.length; j++) {
                    if (state.worklist[j].study_uid == state.activeStudyUid) {
                        state.activeStudyWorklistEntry = JSON.parse(JSON.stringify(state.worklist[j]))
                        break
                    }
                }
            }
        },
        changeNextStudyUid(state, payload) {
            state._nextStudyUid = payload
        },
        changePreviousStudyUid(state, payload) {
            state._previousStudyUid = payload
        },
        changeReportFonts(state, payload) {
            state.reportFonts = payload
        },
        changeReportPhraseLut(state, payload) {
            state.reportPhraseLut = payload
        },
        changeReportSettings(state, payload) {
            state.reportSettings = payload
        },
        changeSelectedStudyUids(state, payload) {
            let windowUid = '__NoT_VaLiD__'
            let studyUid = '__NoT_VaLiD__'
            if (typeof payload === 'string') {
                windowUid = state.uid
                studyUid = payload
            }
            else {
                windowUid = (payload.windowUid === undefined) ? state.uid : payload.windowUid
                studyUid = payload.studyUid
            }
            state._selectedStudyUids[windowUid] = studyUid

            // Update next and previous study UIDs.
            //
            state._nextStudyUids[windowUid] = null
            state._previousStudyUids[windowUid] = null
            methods.updateNextPreviousUids(state, [windowUid])
            if (windowUid == state.uid) {
                state._selectedStudyUid = studyUid
                state._nextStudyUid = state._nextStudyUids[windowUid]
                state._previousStudyUid = state._previousStudyUids[windowUid]
            }
        },
        changeUserProfile(state, payload) {
            state.userProfile = payload
        },
        changeDocServicesUrl(state, payload) {
            state.docServicesUrl = payload
        },
        changeUploadLimitMb(state, payload) {
            state.uploadLimitMb = payload
        },
        changeMgmtReportItems(state, payload) {
            state.mgmtReportItems = payload
        },
        changeMgmtReportRefreshing(state, payload) {
            state.mgmtReportRefreshing = payload
        },
        changeMgmtReportTS(state, payload) {
            state.mgmtReportTS = payload
        },
        changeMgmtReportType(state, payload) {
            state.mgmtReportType = payload
        },
        changePrevRoute(state, payload) {
            state.prevRoute = payload
        },
        changeQueryLimit(state, payload) {
            state.queryLimit = payload
        },
        changeRefreshViewer(state, payload) {
            state.refreshViewer = payload
        },
        changeReportWindowsEditing(state, payload) {
            state.reportWindowsEditing[payload.windowUid] = payload.editing
        },
        changeReportWindows(state, payload) {
            if (payload.window !== null) {
                state.reportWindows[payload.windowUid] = payload.window
            }
            else {
                delete state.reportWindows[payload.windowUid]
                delete state.reportWindowsEditing[payload.windowUid]
                if (state.viewerWindows[payload.windowUid] === undefined) {
                    delete state._nextStudyUids[payload.windowUid]
                    delete state._previousStudyUids[payload.windowUid]
                    delete state._selectedStudyUids[payload.windowUid]
                }
            }
        },
        changeUserGlobalSettings(state, payload) {
            state.userGlobalSettings = payload
        },
        changeViewerSelectedStudyI(state, payload) {
            state.viewerSelectedStudyI = payload
        },
        changeViewerStudyList(state, payload) {
            state.viewerStudyList = payload
        },
        changeViewerWindowRefresh(state, payload) {
            state.viewerWindowRefresh = payload
        },
        changeViewerWindowsRoute(state, payload) {
            state.viewerWindowsRoute[payload.windowUid] = payload.route
        },
        changeViewerWindows(state, payload) {
            if (payload.window !== null) {
                state.viewerWindows[payload.windowUid] = payload.window
            }
            else {
                delete state.viewerWindows[payload.windowUid]
                delete state.viewerWindowsRoute[payload.windowUid]
                if (state.reportWindows[payload.windowUid] === undefined) {
                    delete state._nextStudyUids[payload.windowUid]
                    delete state._previousStudyUids[payload.windowUid]
                    delete state._selectedStudyUids[payload.windowUid]
                }
            }
        },
        changeWebServicesBaseUrl(state, payload) {
            state.webServicesBaseUrl = payload
        },
        changeWorklist(state, payload) {
            state.worklist = payload
            state.worklistRefreshTime = new Date().toLocaleString(state.locale)
            const windowUids = Object.keys(state._selectedStudyUids)
            methods.updateNextPreviousUids(state, windowUids)
        },
        changeWorklistCount(state, payload) {
            state.worklistCount = payload
        },
        changeWorklistRefreshFlag(state, payload) {
            state.worklistRefreshFlag = payload
        },
        changeWorklistRefreshing(state, payload) {
            state.worklistRefreshing = payload
        },
        changeWorklistRefreshInterval(state, payload) {
            state.worklistRefreshInterval = payload
        },
        changeWorklistSorted(state, payload) {
            state.worklistSorted = payload
            const windowUids = Object.keys(state._selectedStudyUids)
            methods.updateNextPreviousUids(state, windowUids)
        },
        changeWorklistName(state, payload) {
            state.worklistName = payload
        },
        changeWorklistFilter(state, payload) {
            state.worklistSettings[payload.worklistName] = payload
            if (payload.default) {
                const keys = Object.keys(state.worklistSettings)
                for(var j=0; j < keys.length; j++) {
                    if (payload.worklistName != keys[j]) {
                        state.worklistSettings[keys[j]].default = false;
                    }
                }
            }
            state.worklistNames = Object.keys(state.worklistSettings).filter(item => item !== state.adHocWorklistName).sort()
        },
        changeWorklistSettings(state, payload) {
            state.worklistSettings = payload
            state.worklistNames = Object.keys(state.worklistSettings).filter(item => item !== state.adHocWorklistName).sort()
        },
        changeWorklistActions(state, payload) {
            state.worklistActions = payload
        },
        changeWorklistColumns(state, payload) {
            state.worklistColumns = payload
        },
        changeWorklistColumnWidths(state, payload) {
            state.worklistColumnWidths = payload
        },
        changeWorklistTableFilters(state, payload) {
            state.worklistTableFilters = payload
        },
        changeWorklistPerPage(state, payload) {
            state.worklistPerPage = payload
        },
        changeWorklistAltViewers(state, payload) {
            state.worklistAltViewers = payload
        },
        changeWorklistViewers(state, payload) {
            state.worklistViewers = payload
        },
        changeWwwlFavorites(state, payload) {
            state.wwwlFavorites = payload
        },
        removeWorklistFilter(state, worklistName) {
            delete state.worklistSettings[worklistName]
            state.worklistNames = Object.keys(state.worklistSettings).filter(item => item !== state.adHocWorklistName).sort()
        },
        changeExportStudy(state, payload) {
            state.exportStudy = payload
        }
    },
    actions: {
    }
});