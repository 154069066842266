// Permissions helper functions
//
import Vue from "vue";
import store from "../store";

import permissionDefns from "./permissionDefns.json";

// Customer-level Permissions
//
const MASTER_ADMIN = "master_admin";

// Tenant-level Permissions
//
const CAN_ASSIGN_STUDIES = 'can_assign_studies'
const CAN_DO_QUERY_SEARCH = 'can_do_query_search'
const CAN_DOWNLOAD_REPORT = 'can_download_report'
const CAN_DOWNLOAD_STUDY = 'can_download_study'
const CAN_EDIT_PATIENT_HISTORY = 'can_edit_patient_history'
const CAN_EDIT_PI = 'can_edit_pi'
const CAN_EDIT_REPORTS = 'can_edit_reports'
const CAN_EDIT_WORKLISTS = 'can_edit_worklists'
const CAN_LOCK_STUDY = 'can_lock_study'
const CAN_MARK_STAT = 'can_mark_stat'
const CAN_ROUTE_STUDY = 'can_route_study'
const CAN_SIGN_FINAL = 'can_sign_final'
const CAN_SIGN_PRELIM = 'can_sign_prelim'
const CAN_SHARE_STUDY = 'can_share_study'
const CAN_UPDATE_STUDY_TENANT = 'can_update_study_tenant'
const CAN_UPLOAD_STUDY = 'can_upload_study'
const CAN_USE_TEMPLATE_EDITOR = 'can_use_template_editor'
const CAN_VIEW_IMAGES = 'can_view_images'
const CAN_VIEW_ORDERS = 'can_view_orders'
const CAN_VIEW_PATIENT_HISTORY = 'can_view_patient_history'
const CAN_VIEW_REPORTS = 'can_view_reports'
const CAN_VIEW_STUDIES_ALL = 'can_view_studies_all'
const CAN_VIEW_STUDIES = 'can_view_studies'

// Returns true if user is super administrator.
//
function isSuperAdmin() {
  var yorn = false;
  try {
    yorn =
      store.state.keycloak.tokenParsed.realm_access.roles.includes("admin");
  } catch (err) {
    Vue.$log.error("unable to check user's profile: " + err);
  }
  return yorn;
}


// Returns true if user is master administrator for current customer.
//
function isMasterAdmin() {
    var yorn = false;
    try {
        const customerId = store.state.customerId
        yorn = (store.state.permissions.customers[customerId] && store.state.permissions.customers[customerId].includes(MASTER_ADMIN))
    }
    catch(err) {
        Vue.$log.error("unable to check user's profile: "+err)
    }
    return yorn
}

function isMedDreamUser() {
    var yorn = false;
    try {
        yorn = store.state.keycloak.tokenParsed.realm_access.roles.includes('meddream_user')
    }
    catch(err) {
        Vue.$log.error("unable to check user's profile: "+err)
    }
    return yorn
}
function getAvailableGroupedTenantPermissions() {
 try {
  const assignablePermissionsGroup = [];
  permissionDefns.permissionsGroup.forEach(group => {
    const assignableGroup = {
      name: group.name,
      permissions: isSuperAdmin() ? group.permissions.filter(permission => permission.assignable ==true) : group.permissions.filter(permission => permission.level.toLowerCase() === "tenant" && permission.assignable ==true)
    };
    if(assignableGroup.permissions.length>0){
      assignablePermissionsGroup.push(assignableGroup);
    }
  });
  return assignablePermissionsGroup;
 } catch (error) {
  Vue.$log.error("Error ::: ",error)
 }
}
// Returns true if user has requested permission (e.g., 'can_view_studies') for given group (e.g., 'Hospital A').
//
function hasPermission(group, permission) {
  var yorn = false;
  try {
    // Is user in group and has permission role?
    //
    const groupName = group.startsWith("/") ? group : "/" + group;
    var groupId = groupName
      .replace(/^\//, "")
      .replace(/\//g, "___")
      .replace(/ /g, "_")
      .toUpperCase();
    yorn =
      store.state.keycloak.tokenParsed.groups.includes(groupName) &&
      store.state.permissions.tenants[groupId] &&
      store.state.permissions.tenants[groupId].includes(permission);
  } catch (err) {
    Vue.$log.error(
      "unable to check permission for group=[" +
        group +
        "] permission=[" +
        permission +
        "]: " +
        err
    );
  }
  return yorn;
}

function groupsForPermission(permission) {
  let groups = [];
  try {
    let groupsFromToken = store.state.keycloak.tokenParsed["groups"];
    for (var i = 0; i < groupsFromToken.length; i++) {
      if (hasPermission(groupsFromToken[i], permission)) {
        groups.push(groupsFromToken[i].substring(1));
      }
    }
  } catch {
    groups = [];
  }
  return groups;
}
export default {
  MASTER_ADMIN,

    CAN_ASSIGN_STUDIES,
    CAN_DO_QUERY_SEARCH,
    CAN_DOWNLOAD_REPORT,
    CAN_DOWNLOAD_STUDY,
    CAN_EDIT_PATIENT_HISTORY,
    CAN_EDIT_PI,
    CAN_EDIT_REPORTS,
    CAN_EDIT_WORKLISTS,
    CAN_LOCK_STUDY,
    CAN_MARK_STAT,
    CAN_ROUTE_STUDY,
    CAN_SIGN_FINAL,
    CAN_SIGN_PRELIM,
    CAN_SHARE_STUDY,
    CAN_UPDATE_STUDY_TENANT,
    CAN_UPLOAD_STUDY,
    CAN_USE_TEMPLATE_EDITOR,
    CAN_VIEW_IMAGES,
    CAN_VIEW_ORDERS,
    CAN_VIEW_PATIENT_HISTORY,
    CAN_VIEW_REPORTS,
    CAN_VIEW_STUDIES_ALL,
    CAN_VIEW_STUDIES,

    isMasterAdmin,
    isMedDreamUser,
    isSuperAdmin,
    groupsForPermission,
    hasPermission,
    getAvailableGroupedTenantPermissions
}